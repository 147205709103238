<template>
  <div class="product-hover shadow">
    <div class="product-hover-img-container">
      <VueSlickCarousel
        class="slider-in-product-box"
        v-bind="slickSettings"
        v-if="product.product_images.length"
      >
        <router-link v-for="(image, i) in product.product_images.filter(el => el.medium_image_url)" :key="i"
          :to="slug"
          @click.native="$emit('gotoItem')"
        >
          <img-skeleton height="200px" :src="image.medium_image_url" :title="product.name" :alt="`Perhiasan ${product.brand_name} ${product.name}`" classActive="rounded" @error="replaceByDefaultImage" />
        </router-link>
      </VueSlickCarousel>
      <div class="position-absolute top-0 end-0 px-2 py-1">
        <b-img-lazy
          :src="require(`@/assets/img/wishlist-${found ? 'on' : 'off'}.svg`)"
          :alt="`${found ? 'Remove from' : 'Add to'} wishlist`"
          :title="`${found ? 'Remove from' : 'Add to'} wishlist`"
          width="18"
          class="cursor-pointer"
          @click.native="$emit(found ? 'removeWishlist' : 'addWishlist')"
        />
      </div>
      <div v-if="product.rating.average" class="product-hover-rating">
        <fawesome-pro variant="fas" icon="star" size="sm" class="pr-1 text-warning" />
        <small><strong>{{ product.rating.average.toFixed(1) }}</strong></small>
      </div>
      <!-- <div class="po-status-badge" v-if="product.status_stock == false">
        {{ `PO | ${product.ready_stock_duration}d` }}
      </div> -->
    </div>
    <div class="product-hover-info container-lg"> 
      <div class="row pb-0">
        <div class="col">
          <p class="m-0 text-boxed">
            <b>{{ product.brand_name }}</b> 
            <fawesome-pro :id="`product-hover-store-closed-${product.id}`" variant="fal" icon="exclamation-triangle" size="lg" class="product-hover-store-closed" v-if="product.seller.is_closed"/>
            <br/>
            {{ product.name }}
          </p>
        </div>
        <div class="col-auto text-right">
          <div class="product-hover-icons">
            <template v-if="product.checkout_type != 1 && !/fine/i.test(product.category3_name) && !product.bundle_dynamic_price">
              <fawesome-pro :id="`product-hover-installment-${product.id}`" variant="fal" icon="credit-card" size="lg" @click.native="getInstallmentCalculator" v-if="!isLoadingInstallment"/>
              <fawesome-pro variant="fal" icon="spinner" size="lg" spin v-else />
            </template>
            <fawesome-pro :id="`product-hover-chat-${product.id}`" variant="fal" icon="comment-dots" size="lg" @click.native="chatProduct" />
            <fawesome-pro :id="`product-hover-detail-${product.id}`" variant="fal" icon="info-circle" size="lg" />
            <b-dropdown variant="link" no-caret toggle-class="p-0" right>
              <template #button-content>
                <fawesome-pro :id="`product-hover-share-${product.id}`" variant="fal" icon="share-alt" size="lg" />
              </template>
              <!-- Start share media -->
              <a target="_blank" :href="'https://www.facebook.com/sharer/sharer.php?u=' + feUrl + slug">
                <img
                  src="@/assets/img/facebook-1.png"
                  alt="Share Facebook"
                />
              </a>
              <a target="_blank" :href="'https://twitter.com/intent/tweet?text=' + feUrl + slug">
                <img
                  src="@/assets/img/twitter-1.png"
                  alt="Share Twitter"
                />
              </a>
              <a target="_blank" :href="'https://wa.me/?text=' + feUrl + slug">
                <img
                  src="@/assets/img/whatsapp-sm.png"
                  alt="Share Whatsapp"
                />
              </a>
              <a target="_blank" :href="'https://line.me/R/msg/text/?' + feUrl + slug">
                <img
                  src="@/assets/img/line-sm.png"
                  alt="Share Line"
                />
              </a>
              <!-- End share media -->
            </b-dropdown>
          </div>
        </div>
      </div>
      <div class="row no-gutters">
        <div class="col product-hover-offer">
          <fawesome-pro variant="fal" icon="tag" class="pr-1" title="Price" />
          <div class="product-hover-price">
            <b :class="{ 'text-gold' : (product.product_type == 4 && product.discount_active_bundle) || product.discount_active || product.bundle_dynamic_price }">
              {{
                product.bundle_dynamic_price 
                ? $helpers.product.formatBundlePrice(product)
                : $helpers.format.price(product.price_display)
              }}
            </b><br/>
            <strike v-if="product.product_type == 4 && product.discount_active_bundle"><small>{{ $helpers.product.formatBundlePriceOrigin(product) }}</small></strike>
            <strike v-else-if="product.discount_active"><small>{{ $helpers.format.price(product.price) }}</small></strike>
          </div>
        </div>
        <div class="col product-hover-offer justify-content-end" v-if="installmentPriceDisplay && !/fine|gift/i.test(productCategory) && !product.bundle_dynamic_price">
          <!-- <fawesome-pro variant="fal" icon="credit-card" class="pr-1" title="Installment" /> -->
          <div class="product-hover-price product-installment-price">
            {{ $helpers.format.price(installmentPriceDisplay) }} / 12 Mon
          </div>
        </div>
      </div>
      <hr class="m-0" v-if="product.product_type != 4 && product.product_detail_variance.length" />
      <template v-if="product.product_type != 4">
        <div v-if="!/fine/i.test(productCategory) && product.product_detail_variance.length" class="row no-gutters">
          <div class="col-2"><small>Color</small></div>
          <div class="col-10">
            <label class="radio-badge" v-for="(color, i) in groupProductColors(product)" :key="i">
              <input type="radio" v-model="selectedColor" :value="color.id" hidden @input="chooseColor($event.target.value)">
              <div class="badge" :style="{'--background': color.color}">{{ color.name[0] == '-' ? '-' : color.name }}</div>
            </label>
          </div>
          <template v-if="selectedColor && productCategory == 'Ring'">
            <div class="col-2 text-nowrap">
              <small>Size
                <fawesome-pro v-if="product.category_size.length" variant="fal" icon="info-circle" class="category-size-info ml-0" v-b-modal.modal-category-size @click.native="$emit('showModalCategorySize', product)" />
              </small>
            </div>
            <div class="col-10">
              <label class="radio-badge" v-for="(variant, i) in product.product_detail_variance.filter(el => el.color.id == selectedColor)" :key="i">
                <input type="radio" v-model="selectedVariant" :value="variant.id" hidden :disabled="variant.total_stock == 0">
                <div class="badge">{{ variant.size.international }}</div>
              </label>
            </div>
          </template>
        </div>
      </template>
    </div>
    <router-link
      v-if="product.product_type == 4"
      class="btn btn-wz-proceed btn-block rounded-top-0 mt-auto"
      :to="slug"
      @click.native="$emit('gotoItem')"
    >
      VIEW DETAIL
    </router-link>
    <div v-else class="btn-wz-group rounded-top-0">
      <button type="button" class="btn btn-wz-buy-now" :disabled="!selectedVariant || isLoading" @click="quickCheckout">Buy Now</button>
      <button type="button" class="btn btn-wz-add-bag" :disabled="!selectedVariant" @click="addToCart">
        <!-- <fawesome-pro variant="fal" icon="arrow-right" /> -->
        <img :src="require('@/assets/img/my-cart.svg')" alt="" width="26" class="pl-1 invert" />
        Add to Bag
        <font-awesome v-if="isLoading" icon="spinner" spin />
      </button>
    </div>
    <b-tooltip noninteractive :target="`product-hover-store-closed-${product.id}`" custom-class="tooltip-detail">
      Store Closed
    </b-tooltip>
    <b-tooltip noninteractive :target="`product-hover-installment-${product.id}`" custom-class="tooltip-detail">
      Installment Calculator
    </b-tooltip>
    <b-tooltip noninteractive :target="`product-hover-chat-${product.id}`" custom-class="tooltip-detail">
      Chat
    </b-tooltip>
    <b-tooltip noninteractive :target="`product-hover-detail-${product.id}`" custom-class="tooltip-detail">
      <div class="row text-nowrap">
        <label class="col-md-6 d-flex align-items-center">
          Dimensions
          <span class="ml-auto"> {{ product.dimension }}</span>
        </label>
        <label class="col-md-6 d-flex align-items-center">
          Total Ct (gr)
          <span class="ml-auto"> {{ product.sum_carat_of_gold }}</span>
        </label>
        <label class="col-md-6 d-flex align-items-center">
          Model
          <span class="ml-auto"> {{ product.category3_name }}</span>
        </label>
        <label class="col-md-6 d-flex align-items-center">
          Emas + Diamond (gr)
          <span class="ml-auto"> {{ product.weight_of_gold_plus_diamond }}</span>
        </label>
        <label class="col-md-6 d-flex align-items-center">
          Kadar
          <span class="ml-auto"> {{ product.kadar }}</span>
        </label>
        <label class="col-md-6 d-flex align-items-center">
          Approximate Weight (gr)
          <span class="ml-auto"> {{ product.weight }}gr</span>
        </label>
      </div>
    </b-tooltip>
    <b-tooltip noninteractive :target="`product-hover-share-${product.id}`">
      Share!
    </b-tooltip>
  </div>
</template>
<script>
import { userHelper, toaster } from "@/_helpers";
import { qbHelper } from "@/_services";

const VueSlickCarousel = () => import('vue-slick-carousel');

export default {
  name: 'ProductHover',
  props: ['product', 'installmentPriceDisplay'],
  components: {
    VueSlickCarousel
  },
  data() {
    return {
      isLoading: false,
      selectedColor: null,
      selectedVariant: null,
      productCategory: null,
      slickSettings: {
        centerMode: true,
        centerPadding: "0px",
        slidesToShow: 1,
        arrows: true,
        dots: true,
        infinite: true,
        swipeToSlide: true,
      },
      
      //* Loading state for onclick installment calculator button 
      isLoadingInstallment: false,
    }
  },
  computed: {
    wishlist() {
      return this.$store.getters.getWishlist;
    },
    feUrl() {
      return process.env.VUE_APP_FE_URL;
    },
    apiUrl() {
      return process.env.VUE_APP_API_URL;
    },
    slug() {
      return `/product/${this.product.slug}`;
    },
    found() {
      return this.wishlist.find((el) => el.id == this.product.id)
    },
    userData() {
      return this.$store.getters.getUserData;
    },
  },
  mounted() {
    // determine product category
    this.productCategory = this.product.product_detail_variance.map(el => el.size).filter((v, i, a) => a.findIndex(el => el.id == v.id && el.international[0] != '-') === i).length > 0
      ? 'Ring' : this.product.category3_name
      
    this.autoPickVariant()
  },
  methods: {
    autoPickVariant() {
      if (/fine|gift/i.test(this.productCategory) && this.product.product_detail_variance.length == 1 && this.product.product_detail_variance[0].total_stock > 0) {
        this.selectedColor = this.product.product_detail_variance[0].color.id
        this.selectedVariant = this.product.product_detail_variance[0].id
      }
    },
    chooseColor(color) {
      this.selectedVariant = null
      if (this.productCategory != 'Ring') {
        const toSelect = this.product.product_detail_variance.find(el => el.color.id == color)
        this.selectedVariant = toSelect.total_stock ? toSelect.id : null
      }
    },
    chatProduct() {
      const token = this.$store.getters.getUserToken;
      if (token) {
        const chat_window = document.getElementById("float-chat-window");
        const chat_button = document.getElementById("float-whatsapp");

        if (!chat_window.classList.contains("active")) {
          chat_window.classList.toggle("active");
          chat_button.classList.toggle("active");
        }
        // If user don't have quickblox account
        if (!this.$store.getters.getUserData.quickblox_id) this.qbRegister = true;
        // If user already have quickblox account
        else this.createDialogProduct();
      } else this.$bvModal.show("modalLogin");
    },
    createDialogProduct() {
      this.$store.dispatch({
        type: "setIsLoading",
        key: "dialogList",
        value: true,
      });
      qbHelper.createDialog(
        this.product.seller.id, // merchant id
        this.product.id, // product id
        "product" // type for prepare chat member merchant
      );
    },
    async quickCheckout() {
      this.isLoading = true
      try {
        const res = await this.$api.user.quickCheckout(this.selectedVariant, 1)
        if (res.status === 200) {
          const { data } = res.data
          this.logToAnalytics()
          localStorage.setItem('checkoutData', JSON.stringify(await userHelper.setCartList(data)))
          this.$router.push('/checkout')
        } else toaster.make(res.data.message, 'danger')
      } catch (e) {
        if (e.response) toaster.make(e.response, 'danger')
        console.error(e);
      }
      this.isLoading = false
    },
    async addToCart() {
      if (!this.userData) return this.$bvModal.show('modalLogin')
      this.isLoading = true
      try {
        const res = await this.$api.user.addToCart(this.selectedVariant, 1)
        if (res.status === 200) {
          toaster.make(this.product.name + " has been added to your shopping bag", "success");
          // when user add product to cart
          this.logToAnalytics()
          userHelper.setCartList();
        } else toaster.make(res.data.message, 'danger')
      } catch (e) {
        if (e.response) toaster.make(e.response, 'danger');
        console.error(e);
      }
      this.isLoading = false
    },
    logToAnalytics() {
      this.$firebaseLogEvent("add_to_cart", {
        currency: "IDR",
        items: [
          {
            item_id: this.product.id.toString(),
            item_name: this.product.name,
            item_brand: this.product.brand_name,
            item_category: this.product.category3_name,
            item_variant: this.selectedVariant.toString(),
            currency: "IDR",
            price: this.product.price_display,
            quantity: this.productQuantity,
            size: this.product.product_detail_variance.find(
              (el) => el.id == this.selectedVariant
            ).size.international,
            color: this.product.product_detail_variance.find(
              (el) => el.id == this.selectedVariant
            ).color.name,
          },
        ],
        value: this.product.price_display,
      });
      if(!this.apiUrl.includes('staging')) {
        this.$analytics.fbq.event("AddToCart", {
          content_ids: this.product.id,
          content_type: "product",
          content_name: this.product.name,
          content_category: this.product.category3_name,
          value: this.product.price_display,
          currency: "IDR",
        });
      }
    },
    groupProductColors(product) {
      // https://stackoverflow.com/a/54088854
      return [...product.product_detail_variance.map(el => el.color).reduce((a, b) => {
        a.set(b.id, b)
        return a
      }, new Map()).values()]
    },
    async getInstallmentCalculator() {
      try {
        this.isLoadingInstallment = true;
        const res = await this.$api.product.getInstallmentCalculator(this.product.id);
        if (res.status === 200) {
          this.$emit('showModalInstallmentCalculator', res.data.data);
        } else this.$helpers.toaster.make(res.data.message, 'danger');
        this.isLoadingInstallment = false;
      } catch (error) {
        console.error(error);
        this.isLoadingInstallment = false;
      }
    },
    replaceByDefaultImage(event) {
      event.target.src = require('@/assets/img/default-image.png');
      event.target.classList.add('p-5', 'default-image')
    }
  },
}
</script>
<style scoped>
/* container */
.product-hover {
  position: absolute;
  inset: -15px;
  display: flex;
  flex-direction: column;
  width: calc(100% + 30px);
  min-height: calc(100% + 30px);
  height: fit-content;
  background: #fafafa;
  border-radius: .5rem;
  z-index: 100;
  visibility: collapse;
  opacity: 0;
}

/* header */
.product-hover-img-container:before {
  content: "";
  background: #fff;
  width: calc(100% + 3rem);
  height: calc(100% + 1.5rem);
  inset: -1.5rem;
  border-start-start-radius: .5rem;
  border-start-end-radius: .5rem;
  position: absolute;
}
.product-hover-img-container {
  position: relative;
  margin: 1.5rem 1.5rem 0;
}
.product-hover-rating {
  position: absolute;
  right: .5rem;
  bottom: .5rem;
  border-radius: 50px;
  padding: 0 .25rem;
  background: #ffffff80;
}

/* body */
.product-hover-info {
  text-align: left;
  font-size: 15px;
  line-height: normal;
}
.product-hover-info .row {
  align-items: top;
  padding: .5rem 0;
}
.product-hover-info .product-hover-offer {
  display: flex;
  align-items: center;
  white-space: nowrap;
}
.product-hover-icons {
  display: inline-flex;
  align-items: center;
  background: #ebebeb;
  border-radius: 50px;
  padding: .25rem;
}
.product-hover-icons span.fa-lg {
  color: #000;
  margin: 0 .25rem;
  cursor: pointer;
  font-size: 15px;
}
.product-hover-info .badge {
  border: 1px solid transparent;
  background: #ebebeb;
  font-weight: normal;
  margin: 3px;
  cursor: pointer;
}
.product-hover-info .badge:hover {
  background: #ccc;
}
label.radio-badge {
  font-size: initial;
  margin-bottom: initial;
}
label.radio-badge input[type="radio"]:disabled + .badge {
  color: #0000003b;
  cursor: not-allowed;
}
label.radio-badge input[type="radio"]:checked + .badge {
  border-color: #000;
  background: var(--background, #fff);
}

/* footer */
.btn-wz-group {
  display: flex;
  margin-top: auto;
  color: #fff;
}
.btn-wz-group .btn {
  flex-basis: 0;
  flex-grow: 1;
  padding: .5rem 0;
  max-width: 100%;
  border-radius: 0;
  text-transform: initial;
  font-weight: 400;
}

.tooltip-detail >>> .tooltip-inner {
  max-width: 420px;
}
.product-hover-price {
  line-height: 13px;
  margin-bottom: .25rem;
}
.product-hover-info .product-hover-price {
  font-size: 13px;
  padding-top: .25rem;
}
.product-hover-store-closed {
  font-size: 1rem;
  color: #CA8D00;
  margin-left: .5rem;
}
.product-installment-price {
  color: #cea670
}
</style>
